import React, { useState, useContext } from 'react'
import {
    ProductsContext, ClassesContext, StoreInfoContext,
    SliderImagesContext, UnitsContext
} from '../../Store'
import axios from 'axios'

function ProductList() {

    const [products, setProducts] = useContext(ProductsContext)
    const [classes, setClasses] = useContext(ClassesContext)
    const [storeInfo, setStoreInfo] = useContext(StoreInfoContext)
    const [sliderImgs, setSliderImgs] = useContext(ProductsContext)
    const [units, setUnits] = useContext(UnitsContext)
    ///delete_product
    const handleClick = (id) => {
        debugger
        axios.delete(
            'https://api.garnisne.org/delete_product', {
                headers: {

                }, data: {
                    id: id
                }
        }).then(function () {
            window.location.reload();
        })
            .catch(function (error) {
                console.log(error);
            });;
    }

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Naziv</th>
                        <th>Klasa</th>
                        <th>Cena</th>
                    </tr>
                </thead>
                <tbody>
                {products.map(item => <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{classes.filter(itemc => itemc.id === item.class_id)
                        .map(itemm => itemm.class_name)
                    }</td>
                    <td>{item.price}</td>
                    <td>
                        <button onClick={() => handleClick(item.id)}>Izbriši proizvod</button>
                    </td>
                </tr>
                )}
                </tbody>
            </table>

        </div>
    )
}

export default ProductList
