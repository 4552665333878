import React,{useState, useContext} from 'react'
import axios from 'axios'
import Select  from 'react-select'
import {ProductsContext,ClassesContext,StoreInfoContext,
    SliderImagesContext,UnitsContext} from '../../Store'


function AddProduct() {

    const [products, setProducts] =useContext(ProductsContext)
    const [classes, setClasses] =useContext(ClassesContext)
    const [storeInfo, setStoreInfo] =useContext(StoreInfoContext)
    const [sliderImgs, setSliderImgs] =useContext(ProductsContext)
    const [units, setUnits] =useContext(UnitsContext)

    const [name ,setName] = useState()
    const [fileImage ,setFileImage] = useState()
    const [fileImages ,setFileImages] = useState()
    const [description ,setDescription] = useState()
    const [price ,setPrice] = useState()
    const [unit ,setUnit] = useState()
    const [snizenje ,setSnizenje] = useState()
    const [classid ,setClassid] = useState()
  debugger
    let unitsOptions =[]
     for (let i = 0; i < units.length; i++) {
      unitsOptions.push({value:units[i].id, 
        label:units[i].name}) ;
     }    

     let classesOptions =[]
     for (let j = 0; j < classes.length; j++) {
      classesOptions.push({value:classes[j].id,
        label:classes[j].class_name}) ;
     }
    const handleClick=()=>{
      debugger
      let err =false
      let errMessage = '';
      if (products.filter(item=> item.name === name) && products.filter(item=> item.name === name).length>0) {
        err = true
        errMessage = 'Proizvod sa unetim imenom vec postoji'
      }
      let fd = new FormData()

        fd.append('fileimage',fileImage)
        if(fileImages)
        {
          
          if(fileImages.length > 5)
          {
            errMessage ='Maksimalan broj dodatnih slika je 5'
            err = true
          }
          else{
            for(let i = 0; i < fileImages.length;i++)
            {
              fd.append('fileimages',fileImages[i])
      
            }
          }
        }


        let sn = snizenje? 1:0
        fd.append('name',name)
        fd.append('price',price)
        fd.append('unit',unit.value)
        fd.append('classid',classid.value) 
        fd.append('description',description)
        fd.append('snizenje',sn)    
      if(!err)
      {     
        axios.post('https://api.garnisne.org/add_product', fd)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      else
      {
        alert(errMessage)
      }
    }
  
    return (
      <div className="App">
        <form>
          <p>Product name</p>
          <input required onChange={e =>setName(e.target.value)}  type='text'/>
          <p>Image</p>
          <input required
          onChange={e =>setFileImage(e.target.files[0])} type='file'/>
          <p>Aditional images</p>
          <input onChange={e =>setFileImages(e.target.files)} type='file' multiple/>
          <p>Description</p>
          <textarea onChange={e =>setDescription(e.target.value)}/>
          <p>Metric unit</p>
          <>
            <Select 
              value={unit}
              onChange={value => setUnit(value)}
              options={unitsOptions}
              placeholder="Izaberi meru"/>
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={unit}
              required/>
          </>
          <p>Class_id</p>
          <>            

          <Select 
            value={classid}
            onChange={value => setClassid(value)}
            options={classesOptions}
            placeholder="Izaberi klasu"/>
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0 }}
            value={classid}
            required/>
          </>
          <p>Price</p>
          <input required onChange={e =>setPrice(e.target.value)} type='number'/>
          <p>Snizenje</p>
          <input type="checkbox" 
          onChange={e=>setSnizenje(e.target.checked)}/><br/>
          <button type="submit" onClick={handleClick}>Post</button>
        </form>
      </div>
    );
  }
export default AddProduct
