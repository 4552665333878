import React, {useContext} from 'react'
import {CurrentTab} from './Store'
import AddProduct from './features/addproduct'
import ProductList from './features/productlist'
import Nav from './features/nav'
import './App.css';

function App() {
  const [tabId, setTabId] = useContext(CurrentTab)

  return (
    <div className="App">
      <Nav/>
{     tabId === 1? 
     <AddProduct/>:

     <ProductList/>}
    </div>
  );
}

export default App;
