import React,{useState,useEffect} from 'react';
import createPersistedState from 'use-persisted-state';
import axios from 'axios'

export const ProductsContext = React.createContext()
export const ClassesContext = React.createContext()
export const StoreInfoContext = React.createContext()
export const SliderImagesContext = React.createContext()
export const UnitsContext = React.createContext()
export const CurrentTab = React.createContext()
/*
const useProductsState = createPersistedState('products')
const useStoreInfoState = createPersistedState('store_info')
const useSliderImagesState = createPersistedState('slider_images')
const useClassesState = createPersistedState('classes')
const useUnitsState = createPersistedState('units')
const useCurrentTabState = createPersistedState('tab')*/

let StoreInfoObj = {
    "id":0,
    "store_address":"",
    "store_mail":"",
    "store_name":"",
    "store_delivery_company":"",
    "store_phone":""
  }
  
  let ProductsObj = [{
    "id":0,
    "class_id":0,
    "name":"",
    "description":"",
    "price":0,
    "image":"",
    "created":"",
    "updated":"","snizenje":0,
    "metric_unit_id":0,
    "img_path_list":"",
    "metric_unit":"","min_qty":0,"piece":0
  }]
  
  let ClassesObj =[{
    "id":0,
    "class_name":"",
    "image_path":""
  }]
  
  let SliderImagesObj=[{
    "id":0,
    "image_path":"",
    "page_link":"",
    "class_id":0,
    "product_id":null
  }]
 
  let UnitsObj=[{
    "id": 0,
    "metric_unit": "", 
    "min_qty": 0, 
    "piece": 0, 
    "name": ""
  }]
  debugger

const Store = ({children}) =>{
    let [products, setProducts] = useState(ProductsObj);
    let [store_info, setStore_info] = useState(StoreInfoObj);
    let [slider_images, setSlider_images] = useState(SliderImagesObj);
    let [classes, setClasses] = useState(ClassesObj);   
    let [units, setUnits] = useState(UnitsObj);   
    let [tabId, setTabId] = useState(1);   
     debugger
    useEffect(() => {

        axios.get('https://api.garnisne.org/initial_state')
        .then(res => {
          console.log(res.data)
            setProducts(res.data.products);
            setSlider_images(res.data.slider_images);
            setClasses(res.data.classes);
            setStore_info(res.data.store_info);
            setUnits(res.data.units)

        })
        .catch(err=>{
            console.log(err)
        })
      }, [setProducts,setSlider_images,setClasses,setStore_info,setUnits]);

      return(
        <ClassesContext.Provider value={[classes, setClasses]}>
            <StoreInfoContext.Provider value={[store_info, setStore_info]}>
                <SliderImagesContext.Provider value={[slider_images, setSlider_images]}>
                  <ProductsContext.Provider value={[products, setProducts]}>
                    <UnitsContext.Provider value={[units, setUnits]}>
                      <CurrentTab.Provider value={[tabId,setTabId]}>
                        {children}
                      </CurrentTab.Provider>
                    </UnitsContext.Provider>
                  </ProductsContext.Provider>
                </SliderImagesContext.Provider>
            </StoreInfoContext.Provider>
        </ClassesContext.Provider>

        )
}

export default Store;

