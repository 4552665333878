import React, {useContext} from 'react'
import {CurrentTab} from '../../Store'

function Nav() {
    const [tabId, setTabId] = useContext(CurrentTab)
    const changeTabId=(id)=>{
        setTabId(id)
    }
    return (
        <div>
            {
                tabId === 2 ? 
                <button onClick={()=>changeTabId(1)}>Dodaj proizvod</button>
                :<button onClick={()=>changeTabId(2)}>Izbriši proizvod</button>
            }
        </div>
    )
}

export default Nav
